<template>
  <div class="p-6 w-full cursor-pointer shadow-md rounded-3xl relative" @click="router.push(`/sessions/${session._id}`)"
    :key="componentKey" v-if="!!session && !!session.course && !!session.tutor">
    <div class=" flex flex-col">
      <div class="flex flex-row justify-between">
        <p class=" font-poppins text-sm font-normal leading-5 text-orange">
          {{ session.location === "zoom" || session.location==='gmeet' ? "online" : "in-person" }}
        </p>
        <p class="font-poppins text-sm font-medium leading-5 uppercase">
          {{ time }}
        </p>
      </div>
      <p class="font-poppins text-lg font-medium leading-7">
        {{ session.slot?.day }}
      </p>
      <h2 class="font-poppins text-sm font-medium leading-5">
        {{ session.course.class.name }}
      </h2>
      <p class="text-sm capitalize">
        with
        <span v-if="session.tutor === user.tutor" class="text-primary">{{ session.student?.name }}</span>
        <span v-else class="text-primary">{{ session.tutor.user?.name }}</span>
      </p>
      <div class="py-0.5 px-1 mt-1 max-w-min rounded-md bg-orange-tint text-orange font-bold">
        <p class="whitespace-nowrap text-xs" v-if="session.is_completed">completed</p>
        <p class="whitespace-nowrap text-xs" v-else-if="!session.is_accepted">Awaiting confirmation</p>
        <p class="whitespace-nowrap text-xs" v-else-if="session.payment_fulfilled">confirmed</p>
        <p class="whitespace-nowrap text-xs" v-else>payment pending</p>
      </div>
    </div>

    <div class="absolute right-6 bottom-6">
      <Button @click="router.push(`/sessions/${session._id}`)" shape="ghost"
        v-if="session.tutor === user.tutor && !session.is_accepted && !session.is_completed">
        Reply
      </Button>
    </div>
    <!-- <div class="float-right">
      <div class="inline-flex"> -->
    <!-- <button class="ml-1 mt-2" @click.stop="() => { this.$router.push(`/${user.username}/chats`) }">
          <Bell :userId="session.tutor.user._id" />
        </button> -->
    <!-- </div>
    </div> -->
    <!-- <div class="inline-flex align-middle">
      <img
        :src="
          session.tutor.user._id == user._id
            ? session.student.img_url
              ? session.student.img_url
              : filler_img
            : session.tutor.user.img_url
            ? session.tutor.user.img_url
            : filler_img
        "
        class="w-12 h-12 rounded-full mr-2"
      />
      <div>
        <h2 class="align-middle inline-block text-md font-bold text-orange">
          {{
            session.tutor.user._id == user._id
              ? session.student.name
              : session.tutor.user.name
          }}
        </h2>
        <h2 class="text-md font-extralight">{{ session.location }}</h2>
      </div>
    </div> -->
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { mapState } from "vuex";
import { time_12 } from "../helpers/parser";
import Bell from "./Bell.vue";
import Button from "./Feed/Button.vue";
export default {
  setup() {
    const router = useRouter();
    return { router };
  },
  computed: {
    ...mapState(["user", "filler_img"]),
    time() {
      return time_12(this.session.slot?.time || 0);
    },
  },
  data() {
    return {
      componentKey: 0
    }
  },
  components: {
    Bell,
    Button,
  },
  props: {
    session: Object,
  },
  watch: {
    session: {
      handler: function () {
        this.componentKey++;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>