<template>
  <div class="flex items-center justify-center">
    <div class="relative mt-4 mb-2 w-full ml-5 mr-5">
      <div class="bg-orange-tint rounded-full w-8 h-8 flex items-center justify-center text-orange" @click="goSearch">
        <vue-feather size="20" type="plus" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { isUniqueClass, required } from "../../helpers/validations";
import searchDropdown from "../inputs/searchDropdown.vue";
import Button from "./Button.vue";

export default {
  components: { searchDropdown, Button },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      key: 0,
      selected: null,
    };
  },
  methods: {
    ...mapMutations(["setSearchCallback", "setSearchError"]),
    ...mapActions(["get_class", "enroll"]),
    searchCallback(_class, user_classes) {
      const missing = required(_class);
      if (missing) this.$store.commit("setSearchError", missing);
      const repeats = this.$store.state.user
        ? isUniqueClass(_class, user_classes)
        : false;
      if (repeats) this.$store.commit("setSearchError", repeats);

      if (repeats || missing) return this.$store.commit("fireToast", {
        text: repeats ?? missing,
        type: "error",
      })

      this.$store.dispatch("enroll", { course: _class })
      window.history.back();
    },
    goSearch() {
      this.$router.push('/class-search');
      this.setSearchCallback(this.searchCallback);
    }
  },
}
</script>

