<template>

  <page-layout linkDefault1="/" title="Profile" :backButton="true">
  <Loading v-if="loading" />
    <div v-else>
      <svg class="absolute mx-6 z-40 -my-1" v-if="user.tutor" width="33" height="36" viewBox="0 0 33 36" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.8573 24.1361C9.66732 21.9623 8.57338 20.5328 8.10337 18.9185C8.0844 18.8561 8.06607 18.791 8.04657 18.7182C8.21492 18.5571 8.32442 18.3442 8.35766 18.1136C8.3909 17.8829 8.34594 17.6477 8.22993 17.4456C8.1331 17.2929 7.99384 17.1717 7.82923 17.0969C7.88138 16.286 8.29228 15.6378 9.59789 15.8607C10.6141 16.0357 12.6352 19.1959 12.8936 20.5226C23.5922 9.79114 30.4912 8.65966 30.4912 8.65966C28.9837 8.2929 27.8122 6.62416 26.4741 5.55483C27.2579 4.53067 28.1082 3.5592 29.0196 2.64664C30.1082 1.75128 31.2434 0.914187 32.4205 0.138962C17.9445 -0.339522 10.2289 10.293 0.663765 17.8077C0.783863 18.064 0.899167 18.3221 1.02139 18.5818C2.96253 17.9731 4.89592 17.3658 6.82144 16.7599C6.92469 16.8439 7.02291 16.9275 7.12287 17.0101C6.98108 17.0414 6.84555 17.0963 6.72187 17.1723C6.47138 17.3128 6.28669 17.5468 6.20812 17.823C6.12955 18.0993 6.16349 18.3953 6.30255 18.6466C6.34629 18.7147 6.39915 18.7766 6.45971 18.8303C6.20699 19.1887 6.07194 19.6168 6.07323 20.0553C6.55574 24.6877 7.25094 29.2981 7.98181 33.9015C8.02192 34.185 8.10647 34.4605 8.23245 34.7176C8.27345 34.8037 8.32414 34.8848 8.38352 34.9594L9.06882 35.3294L9.67142 34.7841L10.0828 35.5707C10.3957 35.4993 10.6963 35.3818 10.9746 35.222L11.8664 34.3451C12.8905 30.9423 12.5278 27.2725 10.8573 24.1361Z"
          fill="#FF8303" />
      </svg>
      <div class="flex flex-row items-start justify-start m-5 space-y-0 w-full">
        <Avatar :user="user" isCurrentUser size="large" class="gap-3" />
        <div class="flex flex-col space-y-0 w-full">
          
            <div class="flex flex-row justify-between items-center">
              <tag class="w-14 font-poppins uppercase mx-1" :text="user.university?.abbrev"
                v-if="user.university?.abbrev">
              </tag>
              <Button v-if="isCurrentUser" :shape="'ghost'" color="primary"
                class="ring-1 ring-primary font-poppins text-xs w-38 h-5 p-3 mx-9"
                :class="{ 'mx-32': user.tutor }" @click="this.$router.push('/profile/edit')">Edit Profile</Button>
            </div>
          
          <p class="heading-1 font-poppins capitalize mx-2">{{ user.name }}</p>
          <div class="flex flex-row space-x-3">
            <filter-tag v-if="user.tutor" class="w-16 font-poppins  uppercase" :active="false">Tutor
            </filter-tag>
            <div class="flex flex-row space-x-2 items-center">
              <Button v-if="!isCurrentUser" :key="update" color="primary" :shape="following ? 'default' : 'ghost'"
                class="ring-2 ring-primary px-4 pl-10 py-1" size="small" iconPosition="left" icon="user-plus"
                @click="toggleFollow">
                {{ following ? "following" : "follow " }}</Button>
              <p class="text-body text-primary mt-1" v-if="!isCurrentUser">•</p>
              <p v-if="user.tutor" class="font-poppins text-xs font-bold mx-3 text-gray-400">
                • {{ followers }} Followers
              </p>
              <p v-if="!user.tutor" class="font-poppins text-xs font-bold mx-3 text-gray-400">
                {{ followers }} Followers
              </p>
            </div>
          </div>
        </div>
      </div>

      <div :key="update + this.$store.state.postPopup.update">
                  <post-pop-up :active="showModal || this.$store.state.postPopup.active" :initial="{
                    text: this.$store.state.postPopup.text,
                    isAnonymous: this.$store.state.postPopup.isAnonymous,
                    tags: this.$store.state.postPopup.tags,
                    persist: this.$store.state.postPopup.active,
                  }" />
                </div>


      <div class="flex flex-col items-start w-80 justify-start mr-1 ml-8 mb-3">
        <p class="font-poppins font-semibold text-sm truncate leading-6 align-middle">
          Bio
        </p>
        <p>{{ user.bio }}</p>
      </div>
      <hr>
      <br>
      <div class="flex flex-row items-center justify-between mr-5 ml-8">
        <p class="
            font-poppins font-semibold
            text-sm
            leading-6
            align-middle
            mt-2
          ">
          Classes you are taking now
        </p>
        <ClassSearch />
        <!-- <Button
        v-if="isCurrentUser"
        :shape="full"
        :size="normal"
        :color="primary"
        class="h-9 w-36"
        @click="addClassPopUp()"
        >Add Class</Button
      > -->
      </div>
      <div class="
          flex flex-row
          pb-5
          gap-4
          pl-5
          pr-5
          scrollbar-hide
          whitespace-normal
          scroll scroll-smooth
          overflow-x-auto
          h-auto
        ">
        <class-card v-for="(_class, i) in user.classes" :key="i" :_class="_class"
          :university="user.university.abbrev" />
      </div>
      <hr>
      <br>
      <div class="flex flex-row items-center justify-between mr-5 ml-8" v-if="user.tutor">
        <p class="
            font-poppins font-semibold
            text-sm
            leading-6
            align-middle
            mt-2
            mb-1
          ">
          Classes you are tutoring in
        </p>
        <CourseSearch class="" v-if="user.tutor" />
      </div>
      <div class="flex flex-wrap px-4" v-if="user.tutor">
        <FilterTag class="mb-2 mx-1" :key="i" v-for="(_class, i) in user.tutor.courses">{{ _class.class.name }}
        </FilterTag>
      </div>
      <div v-if="!user.tutor" class="flex flex-row items-center justify-start mr-5 ml-8" >
        <p class="font-poppins font-semibold text-sm leading-6 align-middle">
          Become A Tutor
        </p>
      </div>
      <div class="ml-10 mr-10 mb-5" v-if="!user.tutor">
        <EmptyState text="Become a tutor" @clicked="this.$router.push('/apply')"  :becomeAtutor="true" buttonText="Apply Now!" :showButton="true" />
      </div>
      <hr >
      <div class="flex flex-row items-center justify-start mr-5 ml-8 mt-5" >
        <p class="font-poppins font-semibold text-sm leading-6 align-middle">
          Recent Posts
        </p>
      </div>
      <!-- <addClass :showmodal="true" /> -->
      <div v-if="posts?.length > 0" class="flex flex-col justify-center items-center ml-5 mr-7">
        <post v-for="(_post, i) in posts" :key="i" :post="_post" class="m-5" />
      </div>
      <div class="ml-10 mr-10 mb-5" v-else >
      <EmptyState    text="No posts Yet" iconType="info" :showButton="true" @clicked="openPopUp" />
      </div>
    </div>
  </page-layout>
</template>

<script>
import Loading from "../../components/Loading.vue";
import Button from "../../components/Feed/Button.vue";
import Avatar from "../../components/Feed/Avatar.vue";
import FilterTag from "../../components/Feed/FilterTag.vue";
import ClassCard from "../../components/Feed/ClassCard.vue";
import ClassSearch from "../../components/Feed/ClassSearch.vue";
import Post from "../../components/Feed/Post.vue";
import pageLayout from "../../components/base/pageLayout.vue";
import { getFollowersCount, getProfileByUsername } from "../../helpers/API/Feeds/User";
import SessionInfo from "../../components/SessionInfo.vue";
import CourseSearch from "../../components/Feed/CourseSearch.vue";
import tag from "../../components/tag.vue";
import PostPopUp from "../../components/PostPopUp.vue";
import EmptyState from "../../components/Feed/EmptyState.vue";
export default {
  components: {
    Button,
    Avatar,
    FilterTag,
    ClassCard,
    Post,
    pageLayout,
    SessionInfo,
    Loading,
    ClassSearch,
    CourseSearch,
    tag,
    PostPopUp,
    EmptyState
},

  data() {
    return {
      user: null,
      user2: null,
      firstname: "",
      lastname: "",
      university: "",
      following: false,
      followers: 0,
      update: 0,
      isCurrentUser: true,
      posts: [],
      allSessions: [],
      pastSessions: [],
      upCommingSessions: [],
      updateClass: 0,
      showModal: false,
      loading: true,
      update:0,
    };
  },

  created() {
    this.setUser();
    this.getFollowersCount();    
  },

  methods: {
    toggleFollow() {
      this.following = !this.following;

      if (this.following) this.followers++;
      else this.followers--;

      this.update++;
    },
    async getFollowersCount() {
      await getFollowersCount().then(data => {
        if (data.data > 0) this.followers = data.data;
        else this.followers = 0;
        this.loading = false;
      })
    },
 
    openPopUp(){
    this.showModal=true;
    this.update++;
    },

    setUser() {
      if (!this.$route.params.username) {
        this.user = this.$store.state.user;
        this.user.tutor = this.$store.state.user_tutor;
        this.user.classes.reverse()
        this.isCurrentUser = true;
        this.posts = this.$store.state.posts;
        this.user2 = this.$store.state.profile;
        // this.followers = this.user.feed_user.followers.length;
        return;
      }

      getProfileByUsername(this.$route.params.username).then(({ data }) => {
        this.user2 = data.data.user_profile
        this.posts = data.data.user_posts.data
      });
    },
    getSession() {
      this.allSessions = this.user.sessions;
      for (var i = 0; i < this.allSessions.length; i++) {
        if (this.allSessions[i].is_completed === true) {
          this.pastSessions.push(this.allSessions[i]);
        } else {
          this.upCommingSessions.push(this.allSessions[i]);
        }
      }
    },
    addClassPopUp() {
      this.showModal = true;
      this.updateClass++;
    },
  },
};
</script>

